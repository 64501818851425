
<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>
