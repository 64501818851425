import FemaleActivity from '@/page/FemaleActivity.vue';
import HdgzActivity from '@/page/HdgzActivity.vue';
import {createRouter, createWebHistory} from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'FemaleActivity',
    component: FemaleActivity,
    meta: {
      title: '奢颜'
    }
  },
  {
    path: '/HdgzActivity',
    name: 'HdgzActivity',
    component: HdgzActivity,
    meta: {
      title: '奢颜'
    }
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
});
export default router;
